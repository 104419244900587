import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Defaults } from '../../utils/Utils';

export default function Loading(props) {
    
    return (
        <Box>
            <Typography variant="body2" sx={{
                position:'fixed',
                top: '50%',
                left: '50%',
                transform:'translate(-50%, -50%)',
                zIndex:1
            }}>{props.loadingText||Defaults.loadingText}</Typography>
        </Box>
  );
}
