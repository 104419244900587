export const Colors = {
    assertive: '#008080',//'#972f48', #ff5967 #ff5967 #1DA1F2 #219de9 008080 AA335B
    light: '#fff',
    info: '#029ACF',
    white: '#F8F8F8',
    stable: '#f8f8f8',
    positive: '#4a87ee',
    calm: '#43cee6',
    balanced: '#66cc33',
    energized: '#f0b840',
    red: '#ef473a',
    royal: '#8a6de9',
    royal2: '#7a42f4',
    dark: '#202020',
    darkless: '#585858',//#585858 or #444 #333333
    greylight: '#C0C0C0',
    grey: '#999',
    greydark: '#DCDCDC',
    greydark2: '#DCDCDC',
    //greyish: '#999',
    statusbar:'#333333',
    menubgColor:'#1d1d1d', //'#00203A' #972f48 343060 #1d1d1d
    loginbgColor:'#FAF9FF',
    seagreen:'#3CB371',
    textblack:'#1d1d1d',
    textmuted:'#adadad',
    glossyblack:'#252324',
    subtitle:'#666666',
    appbar:'#F3F3F1',
    bluey: '#DFE8FA',
}

export const textSizes = {
    xs:12,
    sm:13,
    base:14,
    normal:14,
    default:15,
    md: 16,
    lg: 20,
    xl: 24,
    xxl: 28,
    xxxl: 30,
    xxxxl: 32
}

export const Styles = {

    assertivebg:{
		backgroundColor: Colors.assertive
	},
    
    text: {
        fontSize: textSizes.default,
    },
    textxs: {
        fontSize: textSizes.xs,
    },
    textbase: {
        fontSize: textSizes.base,
    },
    textmd: {
        fontSize: textSizes.md,
    },
    textlg: {
        fontSize: textSizes.lg,
    },
    textxl: {
        fontSize: textSizes.xl,
    },
    textxxl: {
        fontSize: textSizes.xxl,
    },
    textxxxl: {
        fontSize: textSizes.xxxl,
    },
    textxxxxl: {
        fontSize: textSizes.xxxxl,
    },
    
    test: {
        backgroundColor: 'blue'
    },
    centerDiv: {
        position:'absolute',
        margin:'auto',
        overflow:'auto'
    },
    centered:{
        position:'fixed',
        top: '50%',
        left: '50%',
        transform:'translate(-50%, -50%)',
        zIndex:1
    },
    shadow:{
        boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        border:'0px red solid',
        borderRadius:'5px'
    },
    disableDiv:{
        pointerEvents:'none',
        //opacity:'0.7',
        opacity:'0.2'
    },
    enableDiv:{
        pointerEvents:'auto'
    },
    underline:{
        display:'block',
        marginTop:'3px',
        marginBottom:'3px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    groupRemove:{
        position: 'relative',
        zIndex: 1,
        top: '0px',
        cursor: 'pointer'
    },
    dockRemove:{
        position: 'absolute',
        zIndex: 1,
        right: '10px',
        top: '20px',
        cursor: 'pointer'
    },
    dockTitle:{
        position: 'absolute',
        zIndex: 1,
        left: '10px',
        top: '10px',
        fontSize:textSizes.base
    },
    dockContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        zIndex: 1,
    },
    buttonSx: {
        textTransform:'none', 
        mb:2, 
        mr:2, 
        //ml:0
    }
};