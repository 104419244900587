import React, {Component} from 'react';
import { updateHtmlTitle, logger, cacheWipeAll, fetchData, Defaults, cacheData } from '../../utils/Utils';

const Login = React.lazy(() => import('../Pages/Login'));
const Demo = React.lazy(() => import('../Pages/Demo'));
const Register = React.lazy(() => import('../Pages/Register'));
const Dashboard = React.lazy(() => import('../Pages/Dashboard'));
//const Landing = React.lazy(() => import('../Pages/Landing'));
const LandingPage = React.lazy(() => import('../LandingPage/index'));
const Error404 = React.lazy(() => import('../Error404/index'));
const Wait = React.lazy(() => import('../Pages/Loading'));

export class Main extends Component {
    constructor(props) 
    {
        super(props)
        
        this.state={
            message:'',
            context:{authData: JSON.parse( fetchData(Defaults.authKey)||'{}' ) },
            blockUI:false,
            loadingText:Defaults.loadingText,
            pageTitle:Defaults.provider,
            page:props.page||'landingpage',
            walled:true,
            snackbar:{open:false,message:'hello'},
            ...props
        }
        
        if ( this.state.page !== 'demo' )
        {
            this.state.page=( this.state.context.authData.hasOwnProperty('token') ? 'dashboard' : this.state.page )
        
            this.state.page=( !this.state.context.authData.hasOwnProperty('token') && props.page === 'dashboard' ? 'landingpage' : this.state.page )    
        }
        
        if ( this.state.context.authData && this.state.context.authData.hasOwnProperty('security') 
                && this.state.context.authData.security.hasOwnProperty('resetPassword') )
        this.state.walled=this.state.context.authData.security.resetPassword
        
        logger({
            file:'index.js',
            context:this.state.context
        })
        
        let wipe=false
        if ( wipe ) cacheWipeAll()
    }
    
    componentDidMount() {
        var that=this
        that._isMounted = true
    }
    
    componentWillUnmount() {
        this._isMounted = false
    }
    
    myCallback = async (dataFromChild) => {
        var that=this
        logger({
            file:'index.js',
            message:'myCallback received',
            dataFromChild:dataFromChild
        })
        
        let page = dataFromChild.page||'landingpage'
        let snackbarObj = ( dataFromChild.hasOwnProperty('snackbar') ? dataFromChild.snackbar : {open:false,message:'hello'} )

        let context={authData: JSON.parse( fetchData(Defaults.authKey)||'{}' ) }
        
        if ( dataFromChild.hasOwnProperty('passwordReset') && dataFromChild.passwordReset )
        {
            that.setState({ walled:false, page:'wait' })
            context.authData.security.resetPassword=false
        } else {
            if ( context.authData && context.authData.hasOwnProperty('security') 
                && context.authData.security.hasOwnProperty('resetPassword') )
            that.setState({ walled:context.authData.security.resetPassword, page:'wait' })
        }
        
        if ( page === 'login' || page === 'register' || page === 'landing' ) 
        {
            sessionStorage.clear()
            if ( this._isMounted )
            this.setState({page:'wait'})
            //window.location.reload(false);
        }
        
        if ( that._isMounted )
        that.setState({ 
            context:context,
            page: page,
            snackbar:snackbarObj,
            pageTitle:dataFromChild.pageTitle || that.state.pageTitle,
            message:dataFromChild.message || '',
            activeTab:dataFromChild.activeTab || 'Assets',
        }, function(){
            cacheData(Defaults.authKey,that.state.context.authData)
        })
    }
    
    render() 
    {        
        if ( this.state.page === 'dashboard' ){
            return([
                <div key={'div1'}>{updateHtmlTitle(this.state.pageTitle)}</div>,
                <div key={'div2'} >
                    <Dashboard callbackParent={this.myCallback} message={this.state.message} context={this.state.context} walled={this.state.walled} snackbar={this.state.snackbar} />
                </div>
            ])
        /*} else if ( this.state.page === 'landing' ){
            return([
                <div key={'div1'}>{updateHtmlTitle(this.state.pageTitle)}</div>,
                <div key={'div2'} >
                    <Landing callbackParent={this.myCallback} message={this.state.message} context={this.state.context} walled={this.state.walled} snackbar={this.state.snackbar} />
                </div>
            ])*/
        } else if ( this.state.page === 'demo' ){
            return([
                <div key={'div1'}>{updateHtmlTitle(this.state.pageTitle)}</div>,
                <Demo key={'div2'} context={this.state.context} />
            ])
        } else if ( this.state.page === 'wait' ){
            return([
                <div key={'div1'}>{updateHtmlTitle(this.state.pageTitle)}</div>,
                <Wait key={'div2'} />
            ])
        } else if ( this.state.page === 'register' && Defaults.enableRegister ){
            return([
                <div key={'div1'}>{updateHtmlTitle(this.state.pageTitle)}</div>,
                <Register key={'div2'} callbackParent={this.myCallback} message={this.state.message} context={this.state.context} />
            ])
        } else if ( this.state.page === 'login' && Defaults.enableLogin ){
            return([
                <div key={'div1'}>{updateHtmlTitle(this.state.pageTitle)}</div>,
                <Login key={'div2'} callbackParent={this.myCallback} message={this.state.message} context={this.state.context} />
            ])
        } else if ( this.state.page === 'landingpage' ){
            return([
                <div key={'div1'}>{updateHtmlTitle(this.state.pageTitle)}</div>,
                <LandingPage key={'div2'} callbackParent={this.myCallback} message={this.state.message} context={this.state.context} />
            ])
        } else {
            //default to 404
            return([
                <div key={'div1'}>{updateHtmlTitle(this.state.pageTitle)}</div>,
                <Error404 key={'div2'} callbackParent={this.myCallback} message={this.state.message} context={this.state.context} />
            ])
        }
    }
}

//export default Main;