import { 
    Box,
    Grid, 
    Typography,
    Stack,
    Link,
    List,
    ListItem,
    ListItemText
} from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Visibility } from '@mui/icons-material';

import { textSizes } from '../css/Styles'

import {Helmet} from "react-helmet";

const axios = require('axios')
const moment = require('moment')
moment().format()

var Validator = require('jsonschema').Validator;//validate json files
var v = new Validator();
var instance = 4;

let settings = {}
try {
    settings = require('./settings.json')
} catch (error) {
    //console.log(error)
}

export const Defaults = {
    mode:'prod', //use prod for live deploy
    logging:true,
    parentProvider:'HAVEit',
    provider:'HAVEit',
    providerUrl:'https://haveit.io', //for prod
    providerUrlLab:'http://localhost:4040', //for dev
    tagline:"Send and receive reminders",
    httpTimeout: 15000,
    authKey:'authKey',
    registerKey:'registerKey',
    loadingText:'Loading view, please wait...',
    WebSocketPort:9898,
    WebSocketTimeoutMs:3000,
    WebSocketSSL:false,
    countryCode:'ZA',
    countryDialingCode:'27',
    country:'South Africa',
    settings:settings,
    otpEnabled:( settings.hasOwnProperty('otpEnabled') ? settings.otpEnabled : true),
    enableLogin:( settings.hasOwnProperty('enableLogin') ? settings.enableLogin : true),
    enableRegister:( settings.hasOwnProperty('enableRegister') ? settings.enableRegister : false),
    baseAssetTypes:["TENANT","PROVIDER","USER","GROUP","REMINDER","LABEL","HOOK","BILLING"],
    baseAssetLabels:["Tenant","Provider","User","Group","Reminder","Label","Hook","Billing"],
    authAPI:( settings.hasOwnProperty('authAPI') ? settings.authAPI : "https://haveit.io/api/app-portal/ms-auth"),
    assetAPI:( settings.hasOwnProperty('assetAPI') ? settings.assetAPI : "https://haveit.io/api/app-asset"),
}

if ( Defaults.mode === 'lab' ) Defaults.providerUrl=Defaults.providerUrlLab

//APIs
//Defaults.authAPI="http://10.0.0.102:32000/app-portal/ms-auth"
//Defaults.assetAPI="http://10.0.0.102:32000/app-asset"
//Defaults.authAPI="https://haveit.io/api/app-portal/ms-auth"
//Defaults.assetAPI="https://haveit.io/api/app-asset"
//Defaults.authAPI="http://10.0.0.102:80/api/app-portal/ms-auth"
//Defaults.assetAPI="http://10.0.0.102:80/api/app-asset"

Defaults.screensLogin=[
    {
        label: 'Login',
        action: 'login',
        show:true,
        sidemenuOnly:false
    },
    {
        label: 'Sign Up',
        action: 'register',
        show:false,
        sidemenuOnly:false
    },
    {
        label: 'Get Android App',
        action: 'downloadAndroid',
        show:true,
        sidemenuOnly:true,
        url:'https://play.google.com/store/apps/details?id=com.haveit'
    },
    {
        label: 'Get iOS App',
        action: 'downloadIOS',
        show:true,
        sidemenuOnly:true,
        url:'https://www.apple.com/app-store'
    }
]

Defaults.assetLinkActions=[
    
]
Defaults.assetUnLinkActions=[
    
]

Defaults.assetActions=[
    {
        label: 'Action',
        action: 'title',
        icon:<Visibility />,
        show:true,
        disabled:true
    },
    {
        label: 'Reminder',
        action: 'addreminder',
        icon:<Visibility />,
        show:true
    },
]

Defaults.assetEditActions=[
    {
        label: 'Title',
        action: 'title',
        icon:<Visibility />,
        show:true,
        disabled:true
    },
    {
        label: 'View',
        action: 'view',
        icon:<Visibility />,
        show:true
    },
    {
        label: 'Edit',
        action: 'edit',
        icon:<Visibility />,
        show:true
    },
    {
        label: 'Edit JSON',
        action: 'editjson',
        icon:<Visibility />,
        show:true
    },
    {
        label: 'Delete',
        action: 'delete',
        icon:<Visibility />,
        show:true
    },
    {
        label: 'Password',
        action: 'password',
        icon:<Visibility />,
        show:true
    }
]

Defaults.ManageTableActions=[
    {
        label: 'Refresh',
        action: 'refresh',
        icon:<RefreshIcon />,
        show:true,
        crud:'read'
    },
    {
        label: 'Settings',
        action: 'settings',
        icon:<SettingsIcon />,
        show:true,
        crud:'admin'
    }
]

Defaults.contactActions=[
    {
        label: 'Action',
        action: 'title',
        icon:<Visibility />,
        show:true,
        disabled:true
    },
    {
        label: 'Message',
        action: 'sendmessage',
        icon:<Visibility />,
        show:true
    },
]

export function openURL(url,target='_self') {
    window.open(url,target);
}

export function getDialogData() {
    return {
        "title":"Dialog",
        "body":<div>Hello World</div>,
        "width":"sm", 
        "open":false
    }
}

export function getModalData() {
    return {
        "title":"Title",
        "body":<div>Hello World</div>,
        "status":"success",
        "width":"300px", 
        "height":"150px",
        "timeout":Defaults.httpTimeout,
        "show":false
    }
}

export function formatDate(date,format) {
    return moment(date).format(format);
}

export function updateHtmlTitle(title) {
    return (
        <div>
            <Helmet>
                <title>{title}</title>
            </Helmet>
        </div>
    )
}

export function logger(m)
{
    if (Defaults.logging)
    {
        m.logDate=new Date() + ''
        console.log(m)
    }
}

export function fetchData(key)
{      
   return sessionStorage.getItem( key )
}

export function cacheData(key,data)
{      
    sessionStorage.setItem( key,JSON.stringify(data) )
    return
}

export function wipeData(key)
{      
   return sessionStorage.removeItem( key )
}

export function cacheWipeAll()
{      
    sessionStorage.clear()
    return
}

export function filterObjByKeyVal(arr,objKey,objVal)
{
    let results={}
    for (let i=0; i<arr.length; i++) 
    {
        if ( arr[i].hasOwnProperty(objKey) && arr[i][objKey] === objVal )
        return arr[i]
    }
    return results;
}

export function searchObjectbyValue(objects,toSearch)
{
    let results = [];
    for (let i=0; i<objects.length; i++) 
    {
        for (let key in objects[i]) 
        {
            if ( objects[i][key] === toSearch )
            {
                results.push(objects[i]);
            }
        }
    }
    return results;
}

export function hasSpecialChar(str,exclude=[]) 
{
    var splChars = "~^!+=/?*|,\":<> []{}`\\';()@&$#%_-";
    for ( var i = 0; i < str.length; i++ ) 
    {
        if ( splChars.indexOf(str.charAt(i)) !== -1 && !exclude.includes(str.charAt(i)) )
        {
            //console.log('xxxx hasSpecialChar char [' + str.charAt(i) + ']')
            return true 
        }
    }
    return false
}

export function capsFirstLetter(str) 
{
    str = str.toLowerCase()
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function excludeFromArr(str) 
{
    str = str.toLowerCase()
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function excludeObjByKeyVal(arr,objKey,excludes)
{
    let p=arr.length
    if ( p > 0 )
    while (p--) 
    {
        if ( excludes.includes(arr[p][objKey]) )
        arr.splice(p, 1);
    }
    return arr;
}

export function validateJSON(obj){
    try
    {
        if ( typeof obj !== 'object' )
        obj=JSON.parse(obj)
        
        return v.validate(instance, obj )
    }
    catch(e)
    {
        return false
    }
}

export function validPhone(phone)
{
    if ( phone === null || phone === undefined || phone === 'undefined' )
    return false
    
    if ( phone.startsWith('+') )
    phone=phone.replace('+','')
    
    if ( hasSpecialChar(phone,['']) )
    return false

    if ( phone.length < 9 )
    return false
                
    if ( ( /^\d+$/.test(phone) ) )
    {
        return true
    }
    else
    {
        return false
    }
}

export function validEmail(email) 
{
    if ( email === null || email === undefined || email === 'undefined' )
    return false
    
    let status = false
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
    {
        status=true
    }
        
    if ( status )
    {
        let email_ = email.split('@')[1]
        email_=email_.split('.')
        
        if ( email_.length < 2 )
        return false
        
        for ( let i in email_ )
        {
            if ( i> 0 && email_[i].length < 2 )
            {
                status=false
                break;
            }
        }
    }
                    
    return status
}

function FetchAPI(config) {   
    return new Promise(function(resolve, reject){
        let httpResult={}
        const axiosInstance = axios.create(config)
        axiosInstance.interceptors.request.use(
            request => requestHandler(request)
        )
        
        const requestHandler = (request) => {
            //modify request here 
            //request.headers['X-Api-Key'] = '1239'
            return request
        }
        
        const axiosResponseErrorHandler = (error) => {
            if ( error.hasOwnProperty('response') && error.response !== null && error.response !== undefined 
                && error.response.hasOwnProperty('data') && error.response.data !== null && error.response.data !== undefined )
            {
                httpResult=error.response.data
                httpResult = { response: { data:error.response.data } }
            } else if ( error.message ) {
                httpResult = { response: { data:{code:500,message:error.message} } }    
            }
        }
            
        const axiosResponseSuccessHandler = (response) => {
            if ( response !== null && response !== undefined && response.hasOwnProperty('data') )
            {
                httpResult = { response: response }
            }
            else
            {
                httpResult = { response: { data:{code:500,message:'Empty reply.'} } }
            }
        }
            
        axiosInstance.interceptors.response.use(
            response => axiosResponseSuccessHandler(response),
            error => axiosResponseErrorHandler(error)
        )
                
        axiosInstance.request(config).then(function(){
                
        }).catch(function (e) {
            httpResult = { response: { data:{code:500,message:e.message} } }
        }).finally(function(){
            logger({
                file:'Utils.js',
                method:config.method,
                url:config.url,
                timeout:config.timeout,
                params:config.data,
                httpResult:httpResult
            })
            resolve(httpResult)
        });
    })
}

export async function doAPI(data,props=null) {
    return new Promise(function(resolve, reject){
        logger({
            file:'Utils.js',
            FetchAPI:data
        })
        FetchAPI({
            method:data.method||'POST',
            url:data.url,
            timeout:data.timeout||Defaults.httpTimeout,
            maxRedirects:1,
            data:data.params||{}
        }).then( res => {
            let response = (res.response && res.response.data ? res.response.data : {code:500,message:'request failed.'})
            
            if ( response.errorMessage )
            response={code:500,message:response.errorMessage}
            
            if ( props !== null && response.code === 403 )
            props.callbackParent({page:'login'})
            
            resolve(response)
        }).catch( e => {
            logger(e)
            resolve({code:400,message:'request failed.'});     
        })
    })
}

export function ObjectSize(obj)
{
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
}

export function sort_unique(arr) 
{
    if (arr.length === 0) return arr;
    arr = arr.sort(function (a, b) { return a*1 - b*1; });
    var ret = [arr[0]];
    for (var i = 1; i < arr.length; i++) { //Start loop at 1: arr[0] can never be a duplicate
        if (arr[i-1] !== arr[i]) {
        ret.push(arr[i]);
        }
    }
    return ret;
}

export function sort_arr_byObj(arr,objKey,order='desc')
{
    if ( order === 'desc')
    return arr.sort((a, b) => ( a[objKey] < b[objKey] ) ? 1 : -1)
    
    if ( order === 'asc')
    return arr.sort((a, b) => ( a[objKey] > b[objKey] ) ? 1 : -1)
}

export function footer(Item)
{
    const label='© ' + new Date().getFullYear() + ' ' + Defaults.provider

    return(
        <div style={{
            display:'flex', 
            background:'white',
            //justifyContent:'center',
            //alignItems:'center',
            height:'6VH',
            position:'absolute',
            bottom:'0',
            padding:'0px',
            paddingBottom:'10px',
            width:'100%',
            paddingLeft:'0px'}}
        >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                <Grid item xs={12}>
                    <Item sx={{boxShadow:'none',width:'100%'}}>
                         <Stack spacing={1} direction="row" sx={{width:'100%'}}>
                            <Box sx={{border:'0px red solid',display:'flex',textAlign:'center',justifyContent:'center',alignItems:'center',width:'100%'}}>
                                <Stack spacing={1} direction="row" sx={{display:'flex',width:'100%'}}>
                                     <Box sx={{
                                        position:'absolute',left:10,
                                        display: {xs:'block',sm:'block',md:'block',lg:'none',xl:'none'},
                                        }}
                                    >
                                        <Typography sx={{fontSize:textSizes.base}}>{label}</Typography>
                                    </Box>
                                    
                                    <Box sx={{
                                       display: {xs:'none',sm:'none',md:'none',lg:'block',xl:'block'}, 
                                        textAlign:'center',justifyContent:'center',alignItems:'center',width:'100%'}}>
                                        <Typography sx={{fontSize:textSizes.base}}>{label}</Typography>
                                    </Box>
                                    <Box sx={{position:'absolute',right:10}}>
                                        <Typography sx={{fontSize:textSizes.sm}}>
                                        <font style={{fontWeight:700}}>
                                            <Link href="/privacy" sx={{cursor:'pointer'}}>Privacy Policy</Link> 
                                            <font style={{display:'none'}}>
                                                <font>&nbsp;&nbsp;</font> / <font>&nbsp;&nbsp;</font>
                                            </font> 
                                            <Link href="/terms" sx={{display:'none',cursor:'pointer'}}>Terms and Conditions</Link>
                                        </font>
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </Item>
                </Grid>
            </Grid>        
        </div>
    )
}
export function validateFormField(fields,fieldIndex,value)
{
    return new Promise(async function(resolve, reject){
        try
        {
            if ( !fields[fieldIndex]['specialCharsAllowed'] )
            {
                if ( hasSpecialChar(value,fields[fieldIndex]['ignoreSpecialChars']) )
                throw new Error('Special characters not allowed')
            }
             
            if ( fields[fieldIndex]['type'] === 'number' && isNaN(value) )
            throw new Error('Entry must be a number')
            
            if ( fields[fieldIndex]['type'] === 'image' || fields[fieldIndex]['type'] === 'audio' 
                || fields[fieldIndex]['type'] === 'video' || fields[fieldIndex]['type'] === 'file' )
            {                
                if ( value['size'] > 1050000 )
                {
                    value=''
                    throw new Error('File size cannot be larger than 1MB')
                }
                
                let objURL = URL.createObjectURL( value )
                let objName = new Date().getTime() + '.' + value['type'].split('/')[1] 

                fields[fieldIndex]['negativeMessage']=''
                fields[fieldIndex]['status']=1
                fields[fieldIndex]['value']=objName
                fields[fieldIndex]['defaultValue']=objURL
            }
            else 
            {
                fields[fieldIndex]['negativeMessage']=''
                fields[fieldIndex]['status']=( value.length < 1 && !fields[fieldIndex]['allowEmptyValue'] ? 0 : 1 )
                fields[fieldIndex]['value']=value
                fields[fieldIndex]['defaultValue']=fields[fieldIndex]['value']
            }
            
            resolve(fields)
        }
        catch(e)
        {
            console.log(e)
            fields[fieldIndex]['negativeMessage']=e.message
            fields[fieldIndex]['status']=-1
            fields[fieldIndex]['value']=value
            fields[fieldIndex]['defaultValue']=fields[fieldIndex]['value']
            resolve(fields)
        }
    })
}

export const copyAssetUrl = (text) => {
    return new Promise((resolve, reject) => {
        if ( !text.startsWith('http') )
        text = 'https://' + text
        navigator.clipboard.writeText(text)
        .then(() => {
            //console.log('Text copied to clipboard:', text);
            resolve(true);
        })
        .catch((error) => {
            //console.error('Error copying text to clipboard:', error);
            reject(false);
        });
    });
};

export const copyText = (text) => {
    return new Promise((resolve, reject) => {
        if (!navigator.clipboard || !navigator.clipboard.writeText) {
            resolve({code:500, message:'Clipboard API not supported.'});
        } else {
          navigator.clipboard.writeText(text)
            .then(() => {
                resolve({code:200,message:'Copied.'});
            })
            .catch(error => {
                resolve({code:500,message:'Failed to copy text.'});
            });
        }
    });
};

export const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
        const targetScroll = sectionElement.offsetTop - offset;
        sectionElement.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
            top: targetScroll,
            behavior: 'smooth',
        });
    }
};

export const dummyList = (len) => {
    return(
        <List>
            {Array.from({ length: len }).map((_, index) => (
              <ListItem key={index}>
                <ListItemText primary={`Item ${index + 1}`} />
              </ListItem>
            ))}
        </List>
    )
}

export const typeOfVariable = (variable) => {
    if ( Array.isArray(variable) ) {
        return 'array'
    } else if ( typeof variable === 'object' ) {
        return 'object'
    } else {
        return (typeof variable)
    }
}