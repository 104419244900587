import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  //Link
} from "react-router-dom";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Colors } from './assets/css/Styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Main } from './assets/components/Main';
import Error404 from './assets/components/Error404/index';
import Error500 from './assets/components/Error500/index';
import Example from './assets/components/Example/index';

import Loading from './assets/components/Pages/Loading'

const loading = () => <Loading />

const theme = createTheme({
    palette: {
      primary: {
        main:Colors.assertive
      }
    },
    /*typography: {
        fontFamily: [
          ''Public Sans',
          'Poppins',
          'sans-serif',
    
        ].join(','),
    }*/
})

class App extends Component {

    componentDidMount() {
        
    }
    
    componentWillUnmount() {
        
    }

    render() {
        return (
            <div>
            <React.Suspense fallback={loading()}>
                <Router>
                    <ThemeProvider theme={theme}>
                        <div>
                            <Switch>
                                <Route exact path="/">
                                    <Main {...this.props} />
                                </Route>

                                <Route exact path="/eg">
                                    <Main {...this.props} />
                                </Route>

                                <Route exact path="/c/*">
                                    <Main {...this.props} />
                                </Route>

                                <Route exact path="/privacy">
                                    <Main {...this.props} />
                                </Route>
                                
                                <Route exact path="/terms">
                                    <Main {...this.props} />
                                </Route>

                                <Route exact path="/500">
                                    <Error500 />
                                </Route>

                                <Route exact path="/example">
                                    <Example />
                                </Route>

                                <Route exact path="/demo">
                                    <Main page={'demo'} />
                                </Route>

                                <Route exact path="/login">
                                    <Main page={'login'} />
                                </Route>

                                <Route exact path="/register">
                                    <Main page={'register'} />
                                </Route>

                                <Route exact path="/dashboard">
                                    <Main page={'dashboard'} />
                                </Route>

                                {/*<Route exact path="/*">
                                    <Main page={'landing'} />
                                </Route>*/}

                                <Route>
                                    <Error404 />
                                </Route>
                        </Switch>
                        </div>
                    </ThemeProvider>
                </Router>
            </React.Suspense>
            </div>
        );
    }
}
export default App;