import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Defaults, updateHtmlTitle } from '../../utils/Utils';

export default function Error404(props) {
    
    let pageTitle=Defaults.provider
    
    return (
        <Box sx={{p:3}}>
            <div key={'div1'}>{updateHtmlTitle(pageTitle)}</div>
            <Typography variant={'body2'} >
                500 Internal Error.
            </Typography>
        </Box>
  );
}